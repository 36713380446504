#about-page {
  background-color: #fff;
  color: $black;
}

.explainer-card {
  border-radius: 1.5rem;
  color: #fff;
  padding: 5rem;
}
