// variable overrides

// set font family
$font-family-base: 'Unbounded', 'Inter', sans-serif;

// Button
$btn-border-radius: 1.5rem;
$btn-border-radius-lg: 2rem;
$btn-border-radius-sm: 1.5rem;

// Card
$card-border-radius: 20px;

// Colors
$primary: #e6007a;
$primary-darker: #c40061;
$primary-lighter: #ff4da0;
$primary-highlight: #ffe6f4;
$secondary: #6c757d;
$secondary-lighter: #b7b8bb;
$secondary-darker: #172026;
$black: #1e1e1e;
$gray: #efefef;
$white: #fff;
$gray-100: #f9fafb;
$gray-200: #f0f2f5;
$gray-300: #d5dbe0;
$gray-600: #6c757d;
$gray-900: #1e1e1e;

// forms
$form-group-margin-bottom: 2rem;
$input-border-radius: 0.5rem;
$input-border-color: $gray-300;
$input--color: $gray-900;
$input-height: 2.5rem;

// headings
$headings-font-weight: bold;
$h2-font-size: 2.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;

// nav
$nav-pills-border-radius: 1.5rem !important;
$nav-pills-link-active-color: $gray-900 !important;
$nav-pills-link-active-bg: $gray-200 !important;
$navbar-nav-link-padding-x: 1.25rem;
$navbar-dark-color: $gray-600;
$navbar-dark-hover-color: $gray-900;

// Radii
$border-radius: 0.5rem;

// import should be at the end
@import '~bootstrap/scss/bootstrap';
@import 'polkadot-scss/typography';
@import 'polkadot-scss/bg';
@import 'polkadot-scss/button';
@import 'polkadot-scss/card';
@import 'polkadot-scss/footer';
@import 'polkadot-scss/seedphrase';
@import 'polkadot-scss/select';
@import 'polkadot-scss/privacypolicy';
@import 'polkadot-scss/about';
@import 'polkadot-scss/landingpage';
@import 'polkadot-scss/extensiononboarding';
@import 'polkadot-scss/forms';
@import 'polkadot-scss/navbar';
@import 'polkadot-scss/processing';
@import 'polkadot-scss/image';
